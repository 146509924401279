<script setup lang="ts">
</script>

<template>
  <div class="w-100 bg-mage2-linear form-subscribe text-white">
    <div class="container-xl">
      <div class="row py-5">
        <div class="col-12 col-md-6 mb-3 mb-md-0">
          <h5 class="mb-0 text-white">Subscribe for Magento tips and updates</h5>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col">
              <input type="email"
                    placeholder="Enter your email address"
                    class="email text-white form-control rounded-0 bg-primary border-0 shadow-none"/>
            </div>
            <div class="col-auto">
              <button class="btn btn-outline-white rounded-pill px-3 px-md-5">
                <span class="text-uppercase">Subscribe</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
