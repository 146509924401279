/**
 * Reusable helper functions for WP API
 */

export const generateAPIUrl = (baseUrl: string, params: any = {}, endpoint: string = "wp/v2/") => {
    const config = useRuntimeConfig();
    const query = new URLSearchParams(params);

    return config.public.wp_api_url + endpoint + baseUrl + '?' + query.toString();
};

export const useWpData = async (path: string, fields: any = {}, endpoint: string = "wp/v2/", key?: string|null) => {
    const config = useRuntimeConfig();
    const url = generateAPIUrl(path, fields, endpoint);
    const basicAuth = btoa(`${config.wpUsername}:${config.wpPassword}`);
    const options: any = {
        headers: {'Authorization': `Basic ${basicAuth}`},
        server: true,
    };

    if (key) {
        options["key"] = key;
    }

    return useFetch(url, options);
}

export const useFormFields = async (formId: string) => {
    const form = await useWpData(`/contact-forms/${formId}`,{}, 'contact-form-7/v1')

    return form?.data?.value?.properties?.form?.fields || {};
}
