<script setup>
    import {useGQlData} from "~/composables/GraphQlHelper";
    import {blogsQuery} from "~/composables/GraphQlQueries";

    const data = await useGQlData(blogsQuery, {}, 'blogs-query');
</script>
<template>
    <div class="container-xl blogs-div py-5" id="blogs">
        <div class="section-heading my-5"
             data-aos="fade-right"
             data-aos-easing="ease-in-sine"
             data-aos-offset="300">
            <h3>Our</h3>
            <h3>Blogs</h3>
        </div>
        <SectionDivider/>
        <div class="posts-wrapper mt-5 row">
            <template v-for="blog in data?.posts?.nodes">
                <div class="col-12 col-md-6 col-xl-4 d-flex align-items-stretch" data-aos="zoom-in">
                    <div class="card rounded-3 border-0 mb-3 bg-light w-100">
                        <NuxtImg
                            v-if="blog?.featuredImage"
                            loading="lazy"
                            quality="80"
                            height="250" width="375"
                            :src="blog?.featuredImage?.node?.mediaItemUrl"
                            class="card-img-top"
                            :alt="(blog?.featuredImage?.node?.altText) ? blog?.featuredImage?.node?.altText : blog?.featuredImage?.node?.title"
                        />
                        <div v-else class="card-img-top d-flex align-items-center justify-content-center bg-secondary">
                          <nuxt-img loading="lazy"
                                    format="webp"
                                    src="/placeholder.webp"
                                    class="img-fluid"
                                    :alt="`${blog?.title} Image Placeholder`"/>
                        </div>
                        <div class="card-body">
                            <p class="card-text text-uppercase mb-2">
                                <template v-for="category in blog?.categories?.nodes">
                                    <a href="#" class="me-4 fw-bold btn-blog-filter category-link">{{ category?.name }}</a>
                                </template>
                            </p>
                            <p class="mb-3 fw-semibold text-secondary card-date">{{ formatDate(blog?.date) }}</p>
                            <a :href="`/blog/${blog?.slug}`" :title="'test'">
                                <h5 class="card-title">{{ blog?.title }}</h5>
                            </a>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="w-100 d-flex justify-content-end">
          <a href="/blog/page/1/">
            <span class="me-3">View All</span>
            <i class="fa-solid fa-angle-right"></i>
          </a>
        </div>
    </div>
</template>
