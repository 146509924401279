<script setup>    
    import {useGQlData} from "~/composables/GraphQlHelper";
    import {homePageQuery, siteOptionsQuery} from "~/composables/GraphQlQueries";
    import {useSeoData} from "~/composables/SeoHelper";

    const data = await useGQlData(homePageQuery, {}, 'home-page-query');
    const options = await useGQlData(siteOptionsQuery, {}, 'site-options-query');

    const formId = data?.page?.form?.form[0] || '0';
    const favicon = options?.page?.siteOptions?.favicon?.node || null;

    useSeoData(data?.page?.seo);
    if (favicon) {
      useHead({
        link: [
          {
            rel: "icon",
            type: favicon.mimeType,
            href: favicon.mediaItemUrl,
          }
        ],
      });
    }
</script>
<template>
    <div class="home-text d-flex align-items-center homepage-banner w-100 pt-5">
        <div class="container d-md-flex align-items-end">
            <div class="col-md-8">
                <div><span class="text-white home-heading-text">we are a sydney</span></div>
                <div><span class="text-white home-heading-text">based web</span></div>
                <div><span class="text-white home-heading-text">development agency</span></div>
            </div>
            <div class="col-md-4 d-md-flex justify-content-end mt-5 mt-md-0">
                <span class="text-white home-desc-text">We can develop a brand new <strong>Magento</strong> store <br/> for your business, upgrade your <strong>M1 store to <br/> M2</strong>, and provide ongoing maintenance.</span>
            </div>
        </div>
    </div>
    <div id="page-container">
        <Works/>
        <Services/>
        <Partners/>
        <Clients/>
        <Reviews/>
        <Blogs/>
        <ContactUs :id="formId"
                   :title="data?.page?.form?.title"
                   :message="data?.page?.form?.message"
                   :image="data?.page?.form?.image"/>
        <Subscribe/>
    </div>
</template>
