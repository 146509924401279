<script setup lang="ts">
    import {useGQlData} from "~/composables/GraphQlHelper";
    import {clientsQuery} from "~/composables/GraphQlQueries";

    const data = await useGQlData(clientsQuery, {}, 'clients-query');
</script>
<template>
    <div class="clients-div homepage-section bg-dark-primary">
        <div class="container section-heading text-white d-flex align-items-center">
            <div class="heading-title col-md-6">
                <h3>Our</h3>
                <h3>Clients</h3>
                <SectionDivider mode="light"/>
            </div>
        </div>
        <div class="container section-content text-white">
            <div class="clients-row row">
                <template v-for="(client, index) in data?.clients?.nodes">
                    <div class="item col-md-4 d-flex justify-content-center"  data-aos="fade-in">
                        <NuxtImg class="w-auto" format="webp" loading="lazy" :src="client?.featuredImage?.node?.mediaItemUrl"
                            :alt="(client?.featuredImage?.node?.altText) ? client?.featuredImage?.node?.altText : client?.featuredImage?.node?.title"
                        />
                    </div>
                    
                    <template v-if="(index+1)%3 == 0 && index !== (data?.clients?.nodes.length -1)">
                        <div class="divider col-md-4 d-none d-md-flex justify-content-center"  data-aos="fade-in">
                            <hr class="w-100"/>
                        </div>
                        <div  class="divider col-md-4 d-none d-md-flex justify-content-center"  data-aos="fade-in">
                            <hr class="w-100"/>
                        </div>
                        <div  class="divider col-md-4 d-none d-md-flex justify-content-center"  data-aos="fade-in">
                            <hr class="w-100"/>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>
