<script setup>
    import {useGQlData} from "~/composables/GraphQlHelper";
    import {ourServicesQuery} from "~/composables/GraphQlQueries";

    const data = await useGQlData(ourServicesQuery, {}, "services-query");
    const services = data?.services?.nodes;

</script>
<template>
    <div class="services-div homepage-section bg-dark-primary" id="services">
        <div class="container section-heading text-white" data-aos="fade-up" data-aos-duration="1000">
            <div class="row align-items-md-center">
                <div class="heading-title col-md-6">
                    <h3>Our</h3>
                    <h3>Services</h3>
                    <SectionDivider mode="light"/>
                </div>
                <div class="heading-subtitle col-md-6 mt-4 mt-md-0">
                    <span>
                        We can develop a brand new <b>Magento</b> store for your business, upgrade your M1 store
                        to M2, and provide ongoing maintenance. Through innovative development and distinctive 
                        design, we create online experiences that leave a lasting impression and accelerate growth.
                    </span>
                </div>
            </div>
        </div>
        <div class="container section-content text-white">
            <template v-for="(service, index) in services">
                <div :class="(service?.serviceDetails?.imagePosition === 'left' ? 'row' : 'row flex-row-reverse') + ' service-container d-md-flex align-items-md-center mt-5'"  data-aos="fade-up" data-aos-duration="500">
                    <div class="service-card col-md-6">
                        <div class="img-div">
                            <NuxtImg format="webp" loading="lazy" :src="service?.featuredImage?.node?.mediaItemUrl"
                                :alt="(service?.featuredImage?.node?.altText) ? service?.featuredImage?.node?.altText : service?.featuredImage?.node?.title"
                            />
                        </div>
                    </div>
                    <div class="service-card description col-md-6">
                        <h4 class="mb-md-5">{{ service?.title }}</h4>
                        <span v-html="service?.content"></span>
                        <div class="mt-md-5 mt-3"><a class="btn btn-primary" href="#">Call to Action</a></div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
