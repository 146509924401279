<script setup lang="ts">
    import {useGQlData} from "~/composables/GraphQlHelper";
    import {techPartnersQuery} from "~/composables/GraphQlQueries";

    const data = await useGQlData(techPartnersQuery, {}, 'tech-partners-query');
</script>
<template>
    <div class="partners-div homepage-section my-5 my-lg-0">
        <div class="container">
            <div class="row w-100" data-aos="fade-up">
                <div class="col-md mx-1">
                    <span class="text-uppercase text-light-gray label">TECHNOLOGY PARTNERS</span>
                </div>
                <template v-for="partner in data?.techPartners?.nodes">
                    <div class="col-md mx-1 px-4 py-md-0 py-2 mb-3 mb-md-5 mb-lg-0">
                        <NuxtImg format="webp" loading="lazy" :src="partner?.featuredImage?.node?.mediaItemUrl"
                            :alt="(partner?.featuredImage?.node?.altText) ? partner?.featuredImage?.node?.altText : partner?.featuredImage?.node?.title"
                        />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
