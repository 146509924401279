<script setup lang="ts">
import {useFormFields} from "~/composables/ApiHelper";

const props = defineProps<{
  id: string,
  title: string,
  message?: string | null,
  image: {
    node: {
      mediaItemUrl: string,
      srcSet: string,
      title: string,
    }
  }
}>();

const config = useRuntimeConfig();
const fields = await useFormFields(props.id);

onMounted(() => {

  const form = document.getElementById(`frm-${props.id}`) as HTMLFormElement;
  const alert = document.getElementById(`frm-${props.id}-alert`) as HTMLDivElement;
  const msg = document.getElementById(`frm-${props.id}-msg`) as HTMLDivElement;
  const btn = document.getElementById(`frm-${props.id}-btn`) as HTMLButtonElement;
  const icon = document.getElementById(`frm-${props.id}-icon`) as HTMLElement;
  const close = document.getElementById(`frm-${props.id}-close`) as HTMLButtonElement;

  form?.addEventListener('submit', e => e.preventDefault());
  close?.addEventListener('click', () => alert?.classList.add("d-none"));

  function onSubmit() {
    btn?.setAttribute('disabled', 'disabled');
    if (!alert?.classList?.contains('d-none')) {
      alert?.classList?.add('d-none');
    }

    if (!icon?.classList?.contains('d-none')) {
      icon?.classList?.add('d-none');
    }

    const url = form.getAttribute('action') as string;
    const body: FormData = new FormData(form);
    const method: string = 'POST';

    $fetch(url, {method, body})
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          icon?.classList?.add('d-none');
          alert?.classList?.remove('d-none');
          btn?.removeAttribute('disabled');
        });
  }

  window.onSubmit = onSubmit;
});
</script>

<template>
  <div class="contact-us-div homepage-section bg-dark-primary py-5" id="contact">
    <div class="container text-white mb-5 my-5">
      <div class="row">
        <div class="col-12 col-md-6 mb-5 mb-md-0 d-flex flex-column justify-content-center">
          <div class="row mb-5">
            <div class="col-12 section-heading text-white d-flex align-items-center">
              <div class="heading-title col-12">
                <h3>{{ props.title }}</h3>
                <SectionDivider mode="light"/>
              </div>
            </div>
          </div>
          <div class="alert alert-success alert-dismissible d-none"
               :id="`frm-${props.id}-alert`"
               data-bs-theme="dark"
               role="alert">
            <div :id="`frm-${props.id}-msg`">&nbsp;</div>
            <button :id="`frm-${props.id}-close`"
                    class="btn-close shadow-none"
                    aria-label="Close"
                    type="button"></button>
          </div>
          <form method="post"
                class="row g-3"
                :id="`frm-${props.id}`"
                :action="`/wp-json/contact-form-7/v1/contact-forms/${props.id}/feedback`">
            <input type="hidden" name="_wpcf7_unit_tag" :value="`form-${props.id}`"/>
            <template v-for="(v, k) in fields" :key="k">
              <div v-if="v.basetype === 'textarea'" class="col-12">
                <label :for="`field-${k}`" class="form-label">{{ v.labels[0] || "" }}</label>
                <textarea :id="`field-${k}`" :name="v.name" class="form-control" rows="5"></textarea>
              </div>
              <div v-else-if="v.basetype === 'recaptcha'" id="recaptcha"></div>
              <div v-else-if="v.basetype === 'submit'" class="col-12">
                <button :data-sitekey="config?.public?.recaptcha_site_key"
                        class="g-recaptcha btn btn-primary"
                        data-badge="inline"
                        data-callback="onSubmit"
                        :id="`frm-${props.id}-btn`"
                        data-action="submit"
                        type="button">
                  <i :id="`frm-${props.id}-icon`" class="fas fa-spinner fa-pulse d-none"></i>
                  <span class="mx-2">{{ v.values[0] || "" }}</span>
                </button>
              </div>
              <div v-else class="col-md-6">
                <label :for="`field-${k}`" class="form-label">{{ v.labels[0] || "" }}</label>
                <input :name="v.name" :type="v.basetype" class="form-control" :id="`field-${k}`"/>
              </div>
            </template>
          </form>
        </div>
        <div class="col-12 col-md-6">
          <NuxtImg loading="lazy"
                   format="webp"
                   class="img-fluid"
                   :title="props?.image?.node?.title"
                   :srcset="props?.image?.node?.srcSet"
                   :src="props?.image?.node?.mediaItemUrl"/>
        </div>
      </div>
    </div>
  </div>
</template>
