<script setup>
    import {useGQlData} from "~/composables/GraphQlHelper";
    import {reviewsQuery} from "~/composables/GraphQlQueries";

    const data = await useGQlData(reviewsQuery, {}, 'reviews-query');

    // get first item for now
    const review = data?.reviews?.nodes[0] || null;
    
</script>
<template>
    <div v-if=review class="reviews-div homepage-section bg-mage2">
        <div class="container-xl">
            <div class="row wrapper">
                <div class="heading-title mobile text-white mb-5">
                    <h3>What Our</h3>
                    <h3>Clients Say</h3>
                    <SectionDivider mode="light"/>
                </div>
                <div class="col-md-8 mt-5 mt-md-0">
                    <video
                        data-aos="fade-up"
                        playsinline controls preload="metadata"
                        class="w-100 h-100 object-fit-cover review-vid">
                        <source type="video/mp4" :src="review?.reviewDetails?.video?.url">
                    </video>
                </div>
                <div class="col-md-4 text-white d-flex flex-column justify-content-between overflow-hidden">
                    <div class="heading-title desktop" 
                        data-aos="fade-left"
                        data-aos-duration="500"
                    >
                        <h3>What Our</h3>
                        <h3>Clients Say</h3>
                        <SectionDivider mode="light"/>
                    </div>
                    <div class="mt-5"
                        data-aos="fade-left"
                        data-aos-delay="300"
                        data-aos-duration="500"
                    >
                        <span v-html="review?.reviewDetails?.testimony"></span>
                    </div>
                    <SectionDivider mode="light" class="test"
                        data-aos="fade-left"
                        data-aos-delay="400"
                        data-aos-duration="500"
                    />
                    <div class="mt-5"
                        data-aos="fade-left"
                        data-aos-delay="500"
                        data-aos-duration="500"
                    >
                        <h5 class="mb-4 fst-italic"">{{ review?.reviewDetails?.name }}</h5>
                        <small>{{ review?.reviewDetails?.title }}, {{ review?.reviewDetails?.company }}</small>
                    </div>
                    <div class="action mt-2"
                        data-aos="fade-left"
                        data-aos-delay="600"
                        data-aos-duration="500"
                    >
                        <a class="btn btn-primary">Get in touch</a>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>