<script setup>
    import {useGQlData} from "~/composables/GraphQlHelper";
    import {ourWorksQuery} from "~/composables/GraphQlQueries";
    
    const data = await useGQlData(ourWorksQuery, {}, 'works-query');
    const worksData = data?.works;

</script>
<template v-if="worksData">
    <div class="works-div my-5" id="works">
        <div class="container">
            <div class="section-heading">
                <h3>Our</h3>
                <h3>Latest Work</h3>
            </div>
            <SectionDivider/>
            <div class="section-content mt-5 row">
                <template v-for="(work, index) in worksData?.nodes">
                    <template v-if="(Math.floor(index / 2) % 2 === 0)">
                        <div v-if="index % 2" class="work-card col-md-7 mt-md-0 mt-2 position-relative pb-2"  data-aos="zoom-in" data-aos-duration="1000">
                            <NuxtImg class="w-100" format="webp" loading="lazy" :src="work?.featuredImage?.node?.mediaItemUrl"
                                :alt="(work?.featuredImage?.node?.altText) ? work?.featuredImage?.node?.altText : work?.featuredImage?.node?.title"
                            />
                            <div class="work-overlay" style="position:absolute;left:auto;margin:25px;bottom: 0">
                                <a :href="work?.worksDetails?.link ? work?.worksDetails?.link?.url : '#'" class="btn btn-primary" data-v-inspector="components/Works.vue:28:27">{{ work?.worksDetails?.buttonLabel }}</a>
                                <i :class="'fab fa-'+ work?.worksDetails?.platform +' fa-2xl text-white'"/>
                            </div>
                        </div>
                        <div v-else class="work-card col-md-5 mt-md-0 mt-2 position-relative pe-1 pb-2" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="200">
                            <NuxtImg class="w-100" format="webp" loading="lazy" :src="work?.featuredImage?.node?.mediaItemUrl"
                                :alt="(work?.featuredImage?.node?.altText) ? work?.featuredImage?.node?.altText : work?.featuredImage?.node?.title"
                            />
                            <div class="work-overlay" style="
                                position: absolute;
                                left: auto;
                                top: 0;
                                margin: 25px;
                            ">
                                <a :href="work?.worksDetails?.link ? work?.worksDetails?.link?.url : '#'" class="btn btn-primary">{{ work?.worksDetails?.buttonLabel }}</a>
                                <i :class="'fab fa-'+ work?.worksDetails?.platform +' fa-2xl text-white'"/>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="index % 2" class="work-card col-md-5 mt-md-0 mt-2 position-relative pb-2" data-aos="zoom-in" data-aos-duration="1000">
                            <NuxtImg class="w-100" format="webp" loading="lazy" :src="work?.featuredImage?.node?.mediaItemUrl"
                                :alt="(work?.featuredImage?.node?.altText) ? work?.featuredImage?.node?.altText : work?.featuredImage?.node?.title"
                            />
                            <div class="work-overlay" style="
                                position: absolute;
                                left: auto;
                                top: 0;
                                margin: 25px;
                            ">
                                <a :href="work?.worksDetails?.link ? work?.worksDetails?.link?.url : '#'" class="btn btn-primary">{{ work?.worksDetails?.buttonLabel }}</a>
                                <i :class="'fab fa-'+ work?.worksDetails?.platform +' fa-2xl text-white'"/>
                            </div>
                        </div>
                        <div v-else class="work-card col-md-7 mt-md-0 mt-2 position-relative pe-1 pb-2" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="200">
                            <NuxtImg class="w-100" format="webp" loading="lazy" :src="work?.featuredImage?.node?.mediaItemUrl"
                                :alt="(work?.featuredImage?.node?.altText) ? work?.featuredImage?.node?.altText : work?.featuredImage?.node?.title"
                            />
                            <div class="work-overlay" style="position:absolute;left:auto;margin:25px;bottom: 0">
                                <a :href="work?.worksDetails?.link ? work?.worksDetails?.link?.url : '#'" class="btn btn-primary" data-v-inspector="components/Works.vue:28:27">{{ work?.worksDetails?.buttonLabel }}</a>
                                <i :class="'fab fa-'+ work?.worksDetails?.platform +' fa-2xl text-white'"/>
                            </div>
                        </div>
                    </template>
                </template>
                <div v-if="worksData?.nodes?.length > 4" class="d-flex justify-content-center mt-5">
                    <a class="btn btn-outline">Load more work</a>
                </div>
            </div>
        </div>
    </div>
</template>
